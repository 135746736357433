var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_c('feather-icon', {
    attrs: {
      "size": "30",
      "icon": 'PrinterIcon'
    }
  }), _vm._v(" REALISASI LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) "), _c('feather-icon', {
    attrs: {
      "size": "30",
      "icon": 'PrinterIcon'
    }
  })], 1), _c('BCardActions', {
    staticClass: "mt-1",
    attrs: {
      "title": "",
      "sub-title": "",
      "action-collapse": "",
      "collapsed": false,
      "no-actions": true
    }
  }, [_c('Table'), _c('br'), _c('span', {
    staticClass: "mt-2"
  }, [_c('small', [_c('strong', [_c('i', [_vm._v(" [ " + _vm._s(_vm.$t('quotes-realisasi_jasa')) + " 📚 ] ")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }