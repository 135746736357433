<template>
  <div>
    <h1>
      <feather-icon size="30" :icon="'PrinterIcon'" />
      REALISASI LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang )
      <feather-icon size="30" :icon="'PrinterIcon'" />
    </h1>
    <BCardActions
      title=""
      sub-title=""
      action-collapse
      class="mt-1"
      :collapsed="false"
      :no-actions="true"
    >
      <Table />
      <br />
      <span class="mt-2">
        <small>
          <strong>
            <i> [ {{ $t('quotes-realisasi_jasa') }} 📚 ] </i>
          </strong>
        </small>
      </span>
    </BCardActions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Table from './table.vue'

export default {
  components: {
    BCardActions,
    Table
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  }
}
</script>
