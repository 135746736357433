<template>
  <div class="monitoring">
    <b-row>
      <b-col md="8" cols="12">
        <b-form-group>
          <div class="d-flex align-items-center">
            <feather-icon
              v-b-tooltip.hover
              title="Catatan Kegiatan"
              size="25"
              :icon="'MessageSquareIcon'"
              class="mr-1 text-danger"
            />
            <b-badge :variant="'light-danger'">
              {{ meta?.catatan_kegiatan ?? '-' }}
            </b-badge>

            <feather-icon
              v-b-tooltip.hover
              title="Rencana tanggal Berkegiatan"
              size="25"
              :icon="'ClockIcon'"
              class="mr-1 ml-1 text-success"
            />
            <b-badge :variant="'light-success'">
              {{ dateHumanReadAble(meta?.wkt_keg_rencana ?? '') ?? '' }}
            </b-badge>

            <feather-icon
              v-b-tooltip.hover
              title="Terakhir rencana berkegiatan di Update"
              size="25"
              :icon="'ClockIcon'"
              class="mr-1 ml-1 text-warning"
            />
            <b-badge :variant="'light-warning'">
              {{ dateHumanReadAble(meta?.updated_at ?? '') ?? '' }}
            </b-badge>
          </div>
        </b-form-group>
      </b-col>
      <b-col class="text-right mb-2" md="4" cols="12">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-button
            id="tambah-data"
            class="button-tambah mr-1"
            :variant="'success'"
            :disabled="!acl()"
            :to="{
              name: enableCreateForm,
              params: {
                opr_form_kegiatan_laporan_id: meta?.id,
                meta: meta,
                previous_link: {
                  name: 'form-kegiatan-operasional-laporan-realisasi'
                }
              }
            }"
          >
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />
                Realisasikan Kegiatan Operasional
              </strong>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div v-if="yo_isLoading" class="progress-wrapper">
      <b-card-text class="mb-0">
        Memuat data… {{ yo_isLoadingValue + '%' }}
      </b-card-text>
      <b-progress
        :key="yo_isLoadingValue"
        animated
        :value="yo_isLoadingValue"
        variant="info"
        max="100"
        :class="'progress-bar-info'"
      />
    </div>

    <!-- table -->
    <vue-good-table
      ref="goodTable"
      :key="yoRender"
      mode="remote"
      class="yo-good-table"
      :total-rows="total_records"
      :columns="columns"
      :is-loading="yo_isLoading"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder:
          'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      }"
      @on-search="onSearchTable"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        jumpFirstOrLast: true,
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`
      }"
      :group-options="{
        enabled: thereIsGroup
      }"
      compact-mode
      theme="polar-bear"
      style-class="vgt-table bordered table-hover"
      :row-style-class="rowStyleClassFn"
      :line-numbers="true"
      :fixed-header="false"
      max-height="700px"
      @on-selected-rows-change="selectionChanged"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <transition mode="out-in">
          <span v-if="props.column.field === 'jns_kegiatan'">
            <strong
              ><feather-icon :icon="'LayersIcon'" /> Jenis Kegiatan :
            </strong>
            <span
              ><code>{{ props.row.jns_kegiatan }}</code></span
            >
            <br />
            <strong><feather-icon :icon="'BoxIcon'" /> Nama Barang : </strong>
            <span
              ><code>{{ props.row.nama_barang }}</code></span
            >
            <br />
            <strong><feather-icon :icon="'Volume2Icon'" /> Volume : </strong>
            <span
              ><code>{{ props.row.volume }}</code></span
            >
            <br />
            <strong
              ><feather-icon :icon="'CompassIcon'" /> Lokasi Kegiatan :
            </strong>
            <span
              ><code>{{ props.row.lokasi_keg }}</code></span
            >
            <br />
            <strong
              ><feather-icon :icon="'ApertureIcon'" /> Asal Barang :
            </strong>
            <span
              ><code>{{ props.row.asal_barang }}</code></span
            >
            <br />
            <strong
              ><feather-icon :icon="'SendIcon'" /> Tujuan Barang :
            </strong>
            <span
              ><code>{{ props.row.tujuan_barang }}</code></span
            >
            <br />
            <strong
              ><feather-icon :icon="'UserCheckIcon'" /> Pemilik Barang :
            </strong>
            <span
              ><code>{{ props.row.pemilik_barang }}</code></span
            >
            <br />
            <hr />
            <strong><feather-icon :icon="'UsersIcon'" /> TKBM : </strong>
            <span
              ><code>{{ props.row.tkbm }}</code></span
            >
            <br />
            <strong
              ><feather-icon :icon="'ToolIcon'" /> Peralatan
              <strong> ( Operator / Driver )</strong>
            </strong>
            <b-form-tags
              :value="perlatanData(props.row.opr_keg_mb_lp_peralatans)"
              :input-id="`tag-kegiatan`"
              :input-attrs="{
                'aria-describedby': 'tags-remove-on-delete-help'
              }"
              width="75"
              separator=","
              placeholder=""
              :disabled="true"
              remove-on-delete
              no-add-on-enter
            />
          </span>
          <span v-if="props.column.field === 'wkt_keg_rencana'">
            <b-row>
              <b-col>
                <b-avatar
                  v-b-tooltip.hover
                  title="Mulai Berkegiatan"
                  class="mb-1"
                  :variant="`light-primary`"
                  size="35"
                >
                  <feather-icon size="20" :icon="'BatteryChargingIcon'" />
                </b-avatar>
                <i
                  ><span>
                    {{ dateHumanReadAble(props.row.wkt_keg_mulai) }}</span
                  ></i
                >
                <hr />

                <b-avatar
                  v-b-tooltip.hover
                  title="Selesai Berkegiatan"
                  class="mb-1"
                  :variant="`light-primary`"
                  size="35"
                >
                  <feather-icon size="20" :icon="'BatteryIcon'" />
                </b-avatar>
                <strong
                  ><span>
                    {{ dateHumanReadAble(props.row.wkt_keg_selesai) }}</span
                  ></strong
                >

                <hr />
                <b-avatar
                  v-b-tooltip.hover
                  title="Last Updated AT"
                  class="mb-1"
                  :variant="`light-warning`"
                  size="35"
                >
                  <feather-icon size="20" :icon="'ClockIcon'" />
                </b-avatar>
                <strong
                  ><span>
                    {{ dateHumanReadAble(props.row.updated_at) }}</span
                  ></strong
                >
              </b-col>
              <b-col>
                <strong><feather-icon :icon="'ClockIcon'" /> Durasi :</strong>
                <b-badge pill class="d-block" :variant="'primary'">
                  <strong
                    ><span> {{ props.row.wkt_durasi_keg }}</span></strong
                  >
                </b-badge>
                <hr />

                <strong
                  ><feather-icon :icon="'BarChartIcon'" /> Produktivitas / Jam
                  :</strong
                >
                <b-badge pill class="d-block" :variant="'primary'">
                  <strong
                    ><span> {{ props.row.wkt_produktivitas }}</span></strong
                  >
                </b-badge>
                <hr />

                <strong
                  ><feather-icon :icon="'TargetIcon'" /> No. Surat Jalan
                  :</strong
                >
                <b-badge pill class="d-block" :variant="'warning'">
                  <strong
                    ><span> {{ props.row.no_surat_jln }}</span></strong
                  >
                </b-badge>
              </b-col>
              <b-col cols="12">
                <hr />
                <b-row>
                  <b-col>
                    <!-- <b-avatar
                      v-b-tooltip.hover
                      title="Operator/Driver"
                      class="mb-1"
                      :variant="`light-primary`"
                      size="35"
                    >
                      <feather-icon size="20" :icon="'UserIcon'" />
                    </b-avatar>
                    <strong> ( Operator / Driver )</strong> -->
                  </b-col>
                  <b-col>
                    <strong
                      ><feather-icon :icon="'TruckIcon'" /> Nomor Kendaraan
                      Truck :</strong
                    >
                    <b-badge pill class="d-block" :variant="'light-danger'">
                      <strong
                        ><span> {{ props.row.no_kendaraan }}</span></strong
                      >
                    </b-badge>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                :variant="'outline-primary'"
                block
                :to="{
                  name: 'form-kegiatan-operasional-laporan-realisasi-view',
                  params: {
                    id: props.row.id,
                    opr_form_kegiatan_laporan_id: meta?.id,
                    meta: meta,
                    api: 'opr_form_kegiatan_laporan_adm',
                    previous_link: {
                      name: 'form-kegiatan-operasional-laporan-realisasi'
                    }
                  }
                }"
                size="md"
              >
                <feather-icon
                  size="25"
                  class="cursor-pointer"
                  :icon="'PenToolIcon'"
                />
              </b-button>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </transition>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'

export default {
  name: 'CompGoodTable',
  components: {
    VSelect,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BCardActions,
    flatPickr,
    BSpinner,
    BBadge
  },
  props: {
    resolveFetchResponse: Function,
    resolveFetchPayload: Function,
    dateHumanReadAble: Function,
    columns: Array,
    thereIsGroup: Boolean,
    enableCreateForm: String,
    enableViewForm: String
  },
  setup() {
    const { isVerticalMenuCollapsed, anIncomingNotificationRiver } =
      useAppConfig()
    return {
      isVerticalMenuCollapsed /* Fixed Header !false Good Table #issue debounce 300s */,
      anIncomingNotificationRiver /* FirebaseFCM */
    }
  },
  data() {
    return {
      meta: null,
      data_parent: this.$router.currentRoute.params?.data_parent,
      yoRender: 1,
      /* PROGRESS | RENDERING | REFS */
      yoTimeoutDebounce: null,
      yoUserData: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dir: false,
      /* PROGRESS | RENDERING | REFS */

      /* GOOD TABLE DATA() DONT MODIFIED */
      onFiltering: false,
      total_records: 0,
      rows: [],
      selectedRows: [],
      serverParams: this.resolveFetchPayload().serverParams
    }
  },
  computed: {
    perlatanData() {
      return (item) => {
        let our_peralatan = []
        item.forEach(async (element) => {
          const data = `${element.peralatan} ( ${element.operator_driver} )`
          our_peralatan.push(data)
        })
        console.log('our_peralatan', our_peralatan)
        return our_peralatan
      }
    },
    acl() {
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')

      const isViewer = this.$store.state.app.roleName == 'viewer'
      const isJettyMan = this.$store.state.app.roleName == 'jetty man'
      let acl = false
      if (hostCanModified || salCanModified) {
        acl = true
      }
      if (isViewer || isJettyMan) {
        acl = false
      }
      return () => acl
    },
    rowStyleClassFn() {
      return (row) => {
        // console.log(row.form_tbbm_id)
        return 'custom-transition'
      }
    },
    showWarning() {
      return (props) => {
        try {
          if (this.$can('SAL', '')) {
            const {
              no_rpkro,
              no_spog,
              kegiatan_start_time_p1,
              kegiatan_end_time_p1
            } = props.form_tbbm

            if (
              !no_rpkro ||
              !no_spog ||
              !kegiatan_start_time_p1 ||
              !kegiatan_end_time_p1
            ) {
              return true
            } else {
              return false
            }
          } else {
            if (this.$can('TBBM', '')) {
              try {
                if (props.form_tbbm.no_do_bl) {
                  const { no_spog } = props.form_tbbm
                  if (!no_spog) {
                    try {
                      const { no_spog_tb } = props.form_tug_boat
                      if (!no_spog_tb) {
                        return true
                      }
                    } catch (error) {}
                    return true
                  } else {
                    return false
                  }
                }
              } catch (error) {
                // tug boat only
                const { no_spog } = props.form_tbbm
                if (!no_spog) {
                  try {
                    const { no_spog_tb } = props.form_tug_boat
                    if (!no_spog_tb) {
                      return true
                    }
                  } catch (error) {}
                  return true
                } else {
                  return false
                }
              }
            }
            return false
          }
        } catch (error) {
          return false
        }
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formStatusVariant() {
      return (any) => this.$variantColumnFormStatus[any]
    },
    verifiedVariant() {
      return (any) => this.$variantVerified[any]
    }
  },
  watch: {
    isVerticalMenuCollapsed() {
      console.log('collapse', this.isVerticalMenuCollapsed)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        // this.yoRender += 1
        // this.serverParams = {
        //   ...this.serverParams,
        // }
      }, 300)
    },
    anIncomingNotificationRiver() {
      if (!this.serverParams.searchTerm) {
        console.log(
          'anIncomingNotificationRiver ::',
          this.anIncomingNotificationRiver
        )
        let high_light_data =
          this.anIncomingNotificationRiver.data[
            `gcm.notification.screen_re_fetch`
          ]
        let will_be_refresh = ['form_tug_boat', 'form_chain_tbbm']
        let go_refresh = will_be_refresh.some((data) =>
          high_light_data.includes(data)
        )
        console.log('vgo_refresh::', go_refresh)

        if (go_refresh) {
          this.fetchData(true, '')
        }
      }
    },
    'document.body.style.zoom': function () {
      console.log('screen change')
    }
  },
  mounted() {
    // document.body.style.zoom = '100%'
    const isBack = !!this.$router.currentRoute.params?.reFetch
    console.log('mounted', isBack)
    if (isBack) {
      // this.fetchData(true, '')
    }
  },
  destroyed() {
    // document.body.style.zoom = '100%'
  },
  created() {
    const yoUserData = getUserData()
    this.yoUserData = yoUserData
    this.fetchData(true, '')
    console.log('this.$refs.goodTable::', this.$refs.goodTable)
  },
  beforeDestroy() {
    this.isTimerDone()
  },
  methods: {
    /* START FROM HERE DONT MODIFIED */
    moment,
    formatNumber(number) {
      if (number != null) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      } else {
        return 0
      }
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    selectionChanged(params) {
      console.log('selectionChanged: ', params)
      this.selectedRows = params.selectedRows
    },
    onPageChange(params) {
      console.log('onPageChange: ', params)
      // this.updateParams({ page: params })
      this.updateParams({ page: params.currentPage })
      this.fetchData(true, '')
    },

    onPerPageChange(params) {
      console.log('onPerPageChange: ', params)
      // this.updateParams({ perPage: params })
      this.updateParams({ perPage: params.currentPerPage })
      this.fetchData(true, '')
    },

    onSortChange(params) {
      console.log(`onSortChange : ${JSON.stringify(params)}`)
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.fetchData(true, '')
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.fetchData(true, '')
    },
    onSearchTable(params) {
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.updateParams({ searchTerm: params.searchTerm })
        this.updateParams({ page: 1 })
        console.log('search: ', params.searchTerm)
        this.fetchData(true, '')
      }, 300)
    },
    /* END FROM HERE DONT MODIFIED */

    /* FETCH DATA | AXIOS */
    // #fetchData
    fetchData() {
      this.isTimerStart()
      const payload = this.resolveFetchPayload()
      const uri = payload.API_SAL

      this.serverParams = {
        ...this.serverParams
        // ...originalServerParams
      }

      useJwt.http
        .get(`${uri}`, {
          params: { ...this.serverParams }
        })
        .then((res) => {
          const { total_records, meta } = res.data
          this.total_records = total_records
          const get_rows = this.resolveFetchResponse(res).map((data) => ({
            ...data
          }))
          this.rows = get_rows
          this.meta = meta
          this.isTimerDone()
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-global-search {
            border-top: 5px solid #2f2f2f1b;
            border-left: 5px solid #2f2f2f1b;
            border-right: 5px solid #2f2f2f1b;
            .vgt-global-search__input {
              border: none !important;
              .input__icon {
                font-weight: 700 !important;
              }
              .vgt-input {
                font-weight: 700 !important;
                color: black;
                border: double;
              }
            }
          }
          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;
            thead {
              th {
                border: $color-yo-border !important;
              } /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }
            tbody {
              tr.custom-transition {
                transition: opacity 0.5s ease;
              }
              tr.custom-transition-active,
              tr.custom-transition-leave-active {
                transition: opacity 0.5s ease;
              }
              tr.custom-transition-enter-from,
              tr.custom-transition-leave-to {
                opacity: 0;
              }

              th {
                border: $color-yo-border !important;
              } /* left checkbox */
              td {
                border: $color-yo-border !important;
              } /* right checkbox */
            }
          }
          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }
            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
