var render = function () {
  var _vm$meta$catatan_kegi, _vm$meta, _vm$dateHumanReadAble, _vm$meta$wkt_keg_renc, _vm$meta2, _vm$dateHumanReadAble2, _vm$meta$updated_at, _vm$meta3, _vm$meta4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 text-danger",
    attrs: {
      "title": "Catatan Kegiatan",
      "size": "25",
      "icon": 'MessageSquareIcon'
    }
  }), _c('b-badge', {
    attrs: {
      "variant": 'light-danger'
    }
  }, [_vm._v(" " + _vm._s((_vm$meta$catatan_kegi = (_vm$meta = _vm.meta) === null || _vm$meta === void 0 ? void 0 : _vm$meta.catatan_kegiatan) !== null && _vm$meta$catatan_kegi !== void 0 ? _vm$meta$catatan_kegi : '-') + " ")]), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 ml-1 text-success",
    attrs: {
      "title": "Rencana tanggal Berkegiatan",
      "size": "25",
      "icon": 'ClockIcon'
    }
  }), _c('b-badge', {
    attrs: {
      "variant": 'light-success'
    }
  }, [_vm._v(" " + _vm._s((_vm$dateHumanReadAble = _vm.dateHumanReadAble((_vm$meta$wkt_keg_renc = (_vm$meta2 = _vm.meta) === null || _vm$meta2 === void 0 ? void 0 : _vm$meta2.wkt_keg_rencana) !== null && _vm$meta$wkt_keg_renc !== void 0 ? _vm$meta$wkt_keg_renc : '')) !== null && _vm$dateHumanReadAble !== void 0 ? _vm$dateHumanReadAble : '') + " ")]), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 ml-1 text-warning",
    attrs: {
      "title": "Terakhir rencana berkegiatan di Update",
      "size": "25",
      "icon": 'ClockIcon'
    }
  }), _c('b-badge', {
    attrs: {
      "variant": 'light-warning'
    }
  }, [_vm._v(" " + _vm._s((_vm$dateHumanReadAble2 = _vm.dateHumanReadAble((_vm$meta$updated_at = (_vm$meta3 = _vm.meta) === null || _vm$meta3 === void 0 ? void 0 : _vm$meta3.updated_at) !== null && _vm$meta$updated_at !== void 0 ? _vm$meta$updated_at : '')) !== null && _vm$dateHumanReadAble2 !== void 0 ? _vm$dateHumanReadAble2 : '') + " ")])], 1)])], 1), _c('b-col', {
    staticClass: "text-right mb-2",
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "variant": 'success',
      "disabled": !_vm.acl(),
      "to": {
        name: _vm.enableCreateForm,
        params: {
          opr_form_kegiatan_laporan_id: (_vm$meta4 = _vm.meta) === null || _vm$meta4 === void 0 ? void 0 : _vm$meta4.id,
          meta: _vm.meta,
          previous_link: {
            name: 'form-kegiatan-operasional-laporan-realisasi'
          }
        }
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Realisasikan Kegiatan Operasional ")], 1)])])], 1)])], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    ref: "goodTable",
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "total-rows": _vm.total_records,
      "columns": _vm.columns,
      "is-loading": _vm.yo_isLoading,
      "rows": _vm.rows,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      },
      "select-options": {
        enabled: false,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        jumpFirstOrLast: true,
        infoFn: function infoFn(params) {
          return "my own page ".concat(params.firstRecordOnPage);
        }
      },
      "group-options": {
        enabled: _vm.thereIsGroup
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered table-hover",
      "row-style-class": _vm.rowStyleClassFn,
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "on-search": _vm.onSearchTable,
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _vm$meta5;
        return [_c('transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [props.column.field === 'jns_kegiatan' ? _c('span', [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'LayersIcon'
          }
        }), _vm._v(" Jenis Kegiatan : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.jns_kegiatan))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BoxIcon'
          }
        }), _vm._v(" Nama Barang : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.nama_barang))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'Volume2Icon'
          }
        }), _vm._v(" Volume : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.volume))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'CompassIcon'
          }
        }), _vm._v(" Lokasi Kegiatan : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.lokasi_keg))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'ApertureIcon'
          }
        }), _vm._v(" Asal Barang : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.asal_barang))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'SendIcon'
          }
        }), _vm._v(" Tujuan Barang : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.tujuan_barang))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'UserCheckIcon'
          }
        }), _vm._v(" Pemilik Barang : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.pemilik_barang))])]), _c('br'), _c('hr'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'UsersIcon'
          }
        }), _vm._v(" TKBM : ")], 1), _c('span', [_c('code', [_vm._v(_vm._s(props.row.tkbm))])]), _c('br'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'ToolIcon'
          }
        }), _vm._v(" Peralatan "), _c('strong', [_vm._v(" ( Operator / Driver )")])], 1), _c('b-form-tags', {
          attrs: {
            "value": _vm.perlatanData(props.row.opr_keg_mb_lp_peralatans),
            "input-id": "tag-kegiatan",
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "width": "75",
            "separator": ",",
            "placeholder": "",
            "disabled": true,
            "remove-on-delete": "",
            "no-add-on-enter": ""
          }
        })], 1) : _vm._e(), props.column.field === 'wkt_keg_rencana' ? _c('span', [_c('b-row', [_c('b-col', [_c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Mulai Berkegiatan",
            "variant": "light-primary",
            "size": "35"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": 'BatteryChargingIcon'
          }
        })], 1), _c('i', [_c('span', [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.wkt_keg_mulai)))])]), _c('hr'), _c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Selesai Berkegiatan",
            "variant": "light-primary",
            "size": "35"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": 'BatteryIcon'
          }
        })], 1), _c('strong', [_c('span', [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.wkt_keg_selesai)))])]), _c('hr'), _c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Last Updated AT",
            "variant": "light-warning",
            "size": "35"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": 'ClockIcon'
          }
        })], 1), _c('strong', [_c('span', [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.updated_at)))])])], 1), _c('b-col', [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'ClockIcon'
          }
        }), _vm._v(" Durasi :")], 1), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'primary'
          }
        }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(props.row.wkt_durasi_keg))])])]), _c('hr'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BarChartIcon'
          }
        }), _vm._v(" Produktivitas / Jam :")], 1), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'primary'
          }
        }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(props.row.wkt_produktivitas))])])]), _c('hr'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'TargetIcon'
          }
        }), _vm._v(" No. Surat Jalan :")], 1), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'warning'
          }
        }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(props.row.no_surat_jln))])])])], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('hr'), _c('b-row', [_c('b-col'), _c('b-col', [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'TruckIcon'
          }
        }), _vm._v(" Nomor Kendaraan Truck :")], 1), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'light-danger'
          }
        }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(props.row.no_kendaraan))])])])], 1)], 1)], 1)], 1)], 1) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "to": {
              name: 'form-kegiatan-operasional-laporan-realisasi-view',
              params: {
                id: props.row.id,
                opr_form_kegiatan_laporan_id: (_vm$meta5 = _vm.meta) === null || _vm$meta5 === void 0 ? void 0 : _vm$meta5.id,
                meta: _vm.meta,
                api: 'opr_form_kegiatan_laporan_adm',
                previous_link: {
                  name: 'form-kegiatan-operasional-laporan-realisasi'
                }
              }
            },
            "size": "md"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "size": "25",
            "icon": 'PenToolIcon'
          }
        })], 1)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }