<!-- KHUSUS OPERASIONAL -->
<template>
  <Table
    ref-specify="Table"
    :columns="filteredColumns"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-payload="resolveFetchPayload"
    :there-is-group="false"
    :dateHumanReadAble="dateHumanReadAble"
    enable-create-form="form-kegiatan-operasional-laporan-realisasi-create"
    enable-view-form="form-kegiatan-operasional-laporan-realisasi-view"
  />
</template>

<script>
import moment from 'moment'
import Table from './_table.vue'

export default {
  name: 'oprFormKegiatanLaporan',
  components: {
    Table
  },
  data() {
    return {
      columns: [
        {
          label: 'Kegiatan',
          field: 'jns_kegiatan'
        },
        {
          label: 'Realisasi Tanggal Kegiatan',
          field: 'wkt_keg_rencana'
        },
        {
          label: 'Details',
          field: 'action',
          isHidden: this.acl()
        }
      ]
    }
  },
  computed: {
    filteredColumns() {
      return this.columns.filter((column) => {
        return column.isHidden === undefined || column.isHidden === true
      })
    }
  },
  created() {
    // this.$root.$moment.locale('id')
  },
  methods: {
    moment,
    acl() {
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')

      const isViewer = this.$store.state.app.roleName == 'viewer'
      const isJettyMan = this.$store.state.app.roleName == 'jetty man'
      let acl = false
      if (hostCanModified || salCanModified) {
        acl = true
      }
      if (isViewer || isJettyMan) {
        acl = false
      }
      return acl
    },
    resolveFetchPayload() {
      return {
        API_SAL: 'opr_form_kegiatan_laporan_adm',
        serverParams: {
          columnFilters: {},
          searchTerm: '',
          page: 1,
          sort: {
            type: 'DESC',
            field: 'created_at'
          },
          perPage: 7, // override
          opr_form_kegiatan_laporan_id: this.$router.currentRoute.params?.id
        }
      }
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    resolveFetchResponse(response) {
      const { opr_form_kegiatan_laporan_adms } = response.data
      return opr_form_kegiatan_laporan_adms
    }
  }
}
</script>
